























































































































































































import { Component, Vue, Watch } from "vue-property-decorator";
import DatePicker from "@/components/common/Inputs/DatePicker.vue";
import Navbar from "@/components/common/Navbar.vue";
import DataTable from "@/components/common/Tables/DataTable.vue";
import SideDrawer from "@/components/common/SideDrawer.vue";
import TextButton from "@/components/common/Buttons/TextButton.vue";
import { ILink, ITableHeader } from "@/interfaces/common";
import { ISegment } from "@/interfaces/Distributor";
import Illustration from "@/components/common/Illustration.vue";
import DistributorSegmentchannelService from "@/services/DistributorSegmentchannelService";
import Snackbar from "@/components/common/Snackbar.vue";
import ActionablePopup from "@/components/common/Popup/ActionablePopup.vue";
import SearchBar from "@/components/common/SearchBar.vue";

@Component({
  components: {
    SearchBar,
    Navbar,
    DataTable,
    SideDrawer,
    Illustration,
    DatePicker,
    Snackbar,
    ActionablePopup,
    TextButton,
  },
})
export default class Create extends Vue {
  private description: string = "No Segmentation Found";
  private subdescription: string = "Please create Segmentation to begin with";
  private showSegmentList: boolean = true;
  private SegmentLoading: boolean = false;
  private createSegments: boolean = true;
  private sideDrawer: boolean = false;
  private timeout!: NodeJS.Timeout;
  private valid: boolean = false;
  private message: string = "";
  private snackbar: boolean = false;
  private sidedrawerTitle: string = "Add Segmentation";
  private totalSegments: number = 0;
  private menuItems: Array<ILink> = [];
  private pages: number = 0;
  private page: number = 1;
  private selectedSegmentId: number = 0;
  private selectedActionMenu: number = -1;
  private creatingOrViewingSegments: boolean = false;
  private segments: Array<ISegment> = [];
  private inactiveSegments: boolean = false;
  private snackbarColor: string = "success";
  private viewItem: ISegment = {
    id: 0,
    name: "",
    erpid: "",
    isDeleted: false,
    isDeactive: false,
  };
  private defaultItem: ISegment = {
    id: 0,
    name: "",
    erpid: "",
    isDeleted: false,
    isDeactive: false,
  };
  private editedItem: ISegment = {
    id: 0,
    name: "",
    erpid: "",
    isDeleted: false,
    isDeactive: false,
  };

  private tableHeaders: Array<ITableHeader> = [
    {
      text: "Name",
      value: "name",
      sortable: true,
      default: "",
    },
    {
      text: "ERPId",
      value: "erpid",
      sortable: true,
      default: "",
    },

    {
      text: "Actions",
      value: "actions",
      sortable: false,
      default: "",
    },
  ];
  private confirmationPopup: boolean = false;
  private confirmationPopupTitle: string = "";
  private confirmationPopupSubtitle: string = "";
  private confirmationPopupText: string = "";
  private cancelBtnText: string = "";
  private confirmBtnText: string = "";

  get formValidate(): Vue & { validate: () => boolean } {
    return this.$refs.form as Vue & { validate: () => boolean };
  }
  get formReset(): Vue & { reset: () => void } {
    return this.$refs.form as Vue & { reset: () => void };
  }
  get formResetValidation(): Vue & { resetValidation: () => void } {
    return this.$refs.form as Vue & { resetValidation: () => void };
  }
 
  public openDrawer(value: string): void {
    this.sidedrawerTitle = this.createSegments
      ? "Fill Segmentation Details"
      : "View Segmentation Details";
    this.clearForm();
    this.sideDrawer = true;
    this.formResetValidation.resetValidation();
  }
  public closeSideDrawer(value: boolean): void {
    this.hideWindowScrollBar(value);
    this.sideDrawer = value;
  }
  public editSegmentation(value: ISegment): void {
    this.createSegments = false;
    this.openDrawer("");
    this.editedItem = Object.assign(
      {},
      {
        name: value.name,
        erpid: value.erpid,
        id: value.id,
        isDeleted: false,
        isDeactive: false,
      }
    );
  }
  public updateSegment(): void {
    let value = Object.assign({}, this.editedItem);
    this.creatingOrViewingSegments = true;
    DistributorSegmentchannelService.UpdateSegmentation({
      name: value.name,
        erpid: value.erpid,
        id: value.id,
        isDeleted: false,
        isDeactive: false,
    })
      .then((response: any) => {
        this.snackbarColor = "success";
        this.snackbar = true;
        this.message = "Changes Saved Successfully";
        this.showSegmentList = true;
        this.creatingOrViewingSegments = false;
        this.closeSideDrawer(false);
        this.clearForm();
        this.refreshSegmentList();
      })
      .catch((error: { response: { status: number; data: string } }) => {
        this.creatingOrViewingSegments = false;
        if (error.response.status == 400) {
          this.snackbarColor = "red";
          this.snackbar = true;
          this.message = error.response.data;
        }
      });
  }

  public async getSegmentation(
    page: number
  ): Promise<boolean> {
    this.SegmentLoading = true;
    return new Promise<boolean>((res) => {
      DistributorSegmentchannelService.GetDistributorSegments(page)
        .then((response) => {
          this.segments = response.data.segments;
          this.pages = response.data.pages;
          this.totalSegments = response.data.total;
          this.SegmentLoading = false;
          if (this.segments.length > 0) {
            this.showSegmentList = true;
          } else {
            this.showSegmentList = false;
            this.description =
              
                 "No Segmentation Found";
            this.subdescription =
              this.segments.length == 0
                ? "Please create a Segmentation to begin with"
                : "No Segmentation Found ";
          }
          res(true);
        })
        .catch((error) => {
          this.showCommunicationError(error);
          console.log(error);
          this.SegmentLoading = false;
        });
    });
  }
  public showCommunicationError(err: any) {
    this.snackbarColor = "red";
    this.snackbar = true;
    this.message = window.navigator.onLine
      ? `Some Error occured`
      : `Cannot reach server at the moment`;
  }
  private async GetMenuActions(): Promise<void> {
    this.menuItems = [
      {
        Text: "DEACTIVATE",
        Color: "red",
      },
    ];
  }

  public createNewSegment(): void {
    let value = Object.assign({}, this.editedItem);
    this.creatingOrViewingSegments = true;
    DistributorSegmentchannelService.createSegmentation({
        name: value.name,
        erpid: value.erpid,
        id: value.id,
        isDeleted: false,
        isDeactive: false,
    })
      .then((response) => {
        this.snackbarColor = "success";
        this.snackbar = true;
        this.message = "Segmentation Created Successfully";
        this.showSegmentList = true;
        this.creatingOrViewingSegments = false;
        this.closeSideDrawer(false);
        this.clearForm();
        this.refreshSegmentList();
      })
      .catch((error) => {
        this.creatingOrViewingSegments = false;
        if (error.response.status == 400) {
          this.snackbarColor = "red";
          this.snackbar = true;
          this.message = "Please Fill Required(*)/Mandatory Field";
        }
      });
  }

  
  public async getInactiveSegments(): Promise<boolean> {
    this.SegmentLoading = true;
    return new Promise<boolean>((res) => {
      DistributorSegmentchannelService.getInactiveSegments(this.page)
        .then((response) => {
          this.segments = response.data.segments;
          this.pages = response.data.pages;
          this.totalSegments = response.data.total;
          this.SegmentLoading = false;
          if (this.segments.length > 0) {
            this.showSegmentList = true;
          } else {
            this.showSegmentList = false;
            this.description =
              this.segments.length == 0
                ? "No Inactive Segmentation Found"
                : "No matching result found";
            
          }
          res(true);
        })
        .catch((error) => {
          this.showCommunicationError(error);
          console.log(error);
          this.SegmentLoading = false;
        });
    });
  }
  public switchSegments() {
    if (this.inactiveSegments) {
      this.tableHeaders = [
        {
          text: "Name",
          value: "name",
          sortable: true,
          default: "",
        },
        {
          text: "ERPId",
          value: "erpid",
          sortable: true,
          default: "",
        },

        {
          text: "Actions",
          value: "actions",
          sortable: false,
          default: "",
        },
      ];
      this.getInactiveSegments();
    } else {
      this.tableHeaders = [
        {
          text: "Name",
          value: "name",
          sortable: true,
          default: "",
        },
        {
          text: "ERPId",
          value: "erpid",
          sortable: true,
          default: "",
        },

        {
          text: "Actions",
          value: "actions",
          sortable: false,
          default: "",
        },
      ];
      this.getSegmentation(1);
    }
  }
  public changePage(value: number): void {
    this.page = value;
    this.inactiveSegments
      ? this.getInactiveSegments()
      : this.getSegmentation(value);
  }

  public clearForm(): void {
    this.editedItem = Object.assign({}, this.defaultItem);
  }
  private hideWindowScrollBar(hideIt: boolean) {
    window.document.documentElement.style.overflowY = hideIt
      ? "hidden"
      : "scroll";
  }
  public actionablePopup() {
    this.snackbar = true;
    this.confirmationPopup = false;
    this.showSegmentList = true;
  }
  public closeSnackbar(value: boolean): void {
    this.snackbar = value;
  }
  private createOrViewSegment(): void {
    if (!this.creatingOrViewingSegments) this.createSegments ? this.createNewSegment() : this.updateSegment();
  }
  public async refreshSegmentList() {
    this.inactiveSegments
      ? await this.getInactiveSegments()
      : await this.getSegmentation( 1);
  }
    public menuAction(segment: ISegment, commandIndex: number){
           this.selectedSegmentId = segment.id;
           this.selectedActionMenu = commandIndex;
           switch (this.menuItems[commandIndex].Text) {
            case "DEACTIVATE": 
                        this.confirmationPopupTitle = "Confirm Deactivation";
                        this.confirmationPopupSubtitle = "Are you sure you wish to deactivate the " + segment.name + " Segmentation?";
                        this.confirmationPopupText = "";
                        this.cancelBtnText = "Cancel";
                        this.confirmBtnText = "Confirm";
                        this.confirmationPopup = true;
                    break;
                default: console.log("Unhandled menuAction")
            }
        }
    public activateSegment(segment: ISegment){
            this.selectedSegmentId = segment.id;
            this.confirmationPopupTitle = "Confirm Activation";
            this.confirmationPopupSubtitle = segment.name+" Segmentation will be activated.";
            this.confirmationPopupText = "";
            this.cancelBtnText = "Keep Inactive";
            this.confirmBtnText = "Activate";
            this.confirmationPopup = true;
        }

  public confirmAction(value: any): void {
    if (this.inactiveSegments) {
      DistributorSegmentchannelService.activateSegmentation(value)
        .then((res) => {
          this.snackbarColor = "success";
          this.message = "Changes Saved Successfully";
          this.actionablePopup();
          this.switchSegments();
        })
        .catch((err: any) => {
          this.showCommunicationError(err);
          console.log(err);
        });
    } else {
      DistributorSegmentchannelService.deactivateSegmentation(value)
        .then((res) => {
          this.snackbarColor = "red";
          this.message = "Segmentation deactivated and moved to inactive list";
          this.actionablePopup();
          this.refreshSegmentList();
        })
        .catch((err) => {
          this.showCommunicationError(err);
          console.log(err);
        });
    }
  }
  async created() {
    this.SegmentLoading = true;
    this.getSegmentation(1);
    this.GetMenuActions().then((_) => _);
  }
}
