import client from './../apiService';

class DistributorSegmentchannelService{
    async GetDistributorSegments(page: number){
        const url = `DistributorSegmentation/GetSegmentation?page=${page}`;
        var result =  await client.get(url, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return result;
    }
    

    UpdateSegmentation(Segment: object){
        const url = `DistributorSegmentation/UpdateSegmentation`;
        return client.post(url, Segment, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
    }

    async getInactiveSegments(page: number){
        const url = `DistributorSegmentation/GetInactiveSegmentation?page=${page}`;
        return await client.get(url, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }
    createSegmentation(segment: object){
        const url = `DistributorSegmentation/CreateSegmentation`;
        return client.post(url, segment, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
    }
    deactivateSegmentation(Id: number){
        const url = `DistributorSegmentation/DeactivateSegmentation?SegmentationId=${Id}`;
        return client.get(url, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
    }

    activateSegmentation(Id: number){
        const url = `DistributorSegmentation/ActivateSegmentationId?SegmentationId=${Id}`;
        return client.get(url, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
    }
    async GetDistributorChannels(page: number){
        const url = `DistributorChannel/GetChannel?page=${page}`;
        var result =  await client.get(url, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return result;
    }
    

    UpdateChannel(Channel: object){
        const url = `DistributorChannel/UpdateChannel`;
        return client.post(url, Channel, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
    }

    async getInactiveChannel(page: number){
        const url = `DistributorChannel/GetInactiveChannel?page=${page}`;
        return await client.get(url, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }
    createChannel(Channel: object){
        const url = `DistributorChannel/CreateChannel`;
        return client.post(url, Channel, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
    }
    deactivateChannel(Id: number){
        const url = `DistributorChannel/DeactivateChannel?ChannelId=${Id}`;
        return client.get(url, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
    }

    activateChannel(Id: number){
        const url = `DistributorChannel/ActivateChannel?ChannelId=${Id}`;
        return client.get(url, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
    }

}

export default new DistributorSegmentchannelService();